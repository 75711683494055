import { createStore } from 'vuex'

import {lang} from './lang'
import {videos} from './videos'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    videos, lang
  }
})
