const axios = require('axios');

const url = 'https://life30server.ru'
// const url = 'http://localhost:3000'

export const videos = {
  state: {
    videos: [],
    currentVideo: null
  },
  getters: {
    videos (state) {
      return state.videos
    },
    currentVideo (state) {
      return state.currentVideo
    }
  },
  mutations: {
    SET_VIDEOS (state, data) {
      state.videos = data
    },
    SET_VIDEO (state, data) {
      console.log(data)
      state.currentVideo = data
    }
  },
  actions: {
    GET_VIDEO (state, data) {

    },
    GET_VIDEOS({dispatch, commit}, data) {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url + '/all',
        headers: { }
      };

      axios.request(config)
      .then((response) => {
        console.log(JSON.parse(JSON.stringify(response.data)));
        let res = JSON.parse(JSON.stringify(response.data))
        commit('SET_VIDEOS', res)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  modules: {
    
  }
}