<template>
<div class="container">
	<div class="help">Drag me around!</div>
	<div class="plane"></div>

	<div class="app">
		<div class="view">
			<div class="title">Demo App</div>
			<div class="item">Update</div><br>
			<div class="item">Send</div><br>
			<div class="item">Rename</div><br>
			<div class="item close">Exit</div>
		</div>
	</div>
</div>
<div class="info"></div>
</template>

<script>
export default {
	data () {
      return {
         mouse: {
            state: 'up',
            pos: {
               offset: {x: 0, y: 0},
               move: {x: 0, y: 0},
               current: {x: 0, y: 0},
               click: {x: 0, y: 0},
               old: {x: 0, y: 0}
            }
         },
         html: {
            container: document.querySelector('.container'),
            plane: document.querySelector('.plane'),
            appClose: document.querySelector('.app .close')
         },
         gridSize: 20,
         iconList: [
            'bluetooth', 'brightness_high', 'directions_transit', 'settings',
            'event', 'headset', 'help', 'insert_chart', 'library_music'
         ],
         interval: null
      }
   },
   mounted () {

      this.html = this.setupHTML()
      this.listenEvents()

      this.interval = setInterval(() => {
         this.updatePosition()
         this.updateIconSizes()
      }, 1000/60)

   },

   methods: {
      setupHTML(){
         let html = {
            container: document.querySelector('.container'),
            plane: document.querySelector('.plane'),
            appClose: document.querySelector('.app .close')
         }
         for(var i = 0; i < 20; i++){
            var icons = ''
               for(var o = 0; o < 5; o++){

                  icons += `
                     <div class="icon">
                        <div class="draw">
                           <div class="draw__container">
                              <div class="draw-main__block">
                                 <div class="video__container">
                                    <img draggable="false" class="video-preview__img"src="https://ei.phncdn.com/videos/200708/12/347/original/(m=eaf8GgaaaWavb)(mh=tDd4ubbYfE8zo0qI)7.jpg"/>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>`
               }
               html.plane.innerHTML += `<div class="row">${icons}</div>`
         }
         html.icons = document.querySelectorAll('.icon')
         return html
      },
      listenEvents(){
         var that = this
         for(var i = 0; i < this.html.icons.length; i++){
               this.html.icons[i].addEventListener('mouseup', function(e){
               that.eventIconClick(this, e)
            })
         }
         //Для мышки
         this.html.container.addEventListener('mousedown', function(e){ that.eventMouseDown(that.pos(e)) })
         this.html.container.addEventListener('mouseup', function(e){ that.eventMouseUp(that.pos(e)) })
         this.html.container.addEventListener('mouseleave', function(e){ that.eventMouseUp(that.pos(e)) })
         this.html.container.addEventListener('mousemove', function(e){ that.eventMouseMove(that.pos(e)) })

         //Для мобилки
         /*this.html.container.addEventListener('touchstart', this.handleTouchStart, false);
         this.html.container.addEventListener('touchend', this.handleTouchEnd, false);
         this.html.container.addEventListener('touchleave', function(e){ that.eventMouseUp(that.pos(e)) })
         this.html.container.addEventListener('touchmove', function(e){ that.eventMouseMove(that.pos(e)) })
         */
      },
      handleTouchStart (e) {
         this.eventMouseDown(this.pos(e))
      },
      handleTouchEnd (e) {
         console.log("END")
      },
      eventMouseUp(pos){
         if(this.mouse.state == 'up') return
            this.mouse.state = 'up'
      },
      eventMouseDown(pos){
         this.mouse.state = 'down'
         this.mouse.pos.offset = this.utilTransfer(pos)
         this.mouse.pos.move = this.utilTransfer(pos)
      },
      eventMouseMove(pos){
         if(this.mouse.state === 'up') return
         this.mouse.state = "move"

         this.mouse.pos.move = this.utilTransfer(pos)
      },
      eventIconClick(ele, event){
         /*if(this.mouse.state === 'move') return
         ele.classList.add('open')
         this.html.container.classList.add('open')

         var box = ele.getBoundingClientRect()
         var contBox = this.html.container.getBoundingClientRect()
         this.mouse.pos.click = {
         x: this.mouse.pos.current.x - (box.left - contBox.left) + contBox.width/2 - box.width/2,
         y: this.mouse.pos.current.y - (box.top - contBox.top) + contBox.height/2 - box.height/2
         }*/
      },
      eventCloseApp(pos){
         this.html.container.classList.remove('open')
         for(var i = 0; i < this.html.icons.length; i++){
            this.html.icons[i].classList.remove('open')
         }
      },
      updatePosition(){
         if(this.mouse.state == "move"){
            this.mouse.pos.current.x += this.mouse.pos.move.x - this.mouse.pos.offset.x
            this.mouse.pos.current.y += this.mouse.pos.move.y - this.mouse.pos.offset.y
            this.mouse.pos.offset = this.utilTransfer(this.mouse.pos.move)
            this.mouse.pos.click = this.utilTransfer(this.mouse.pos.current)
         }
         if(this.mouse.state == "up"){
            this.mouse.pos.current.x -= (this.mouse.pos.current.x - this.mouse.pos.click.x)/10
            this.mouse.pos.current.y -= (this.mouse.pos.current.y - this.mouse.pos.click.y)/10
         }

         var transform = `translateX(${this.mouse.pos.current.x}px) translateY(${this.mouse.pos.current.y}px)`
         this.html.plane.style.transform = transform
      },
      updateIconSizes(){
         for(var i = 0; i < this.html.icons.length; i++){
             
            var contBox = this.html.container.getBoundingClientRect()
            var iconBox = this.html.icons[i].getBoundingClientRect()

            var iconCenter = {
             x: iconBox.left+iconBox.width/2,
             y: iconBox.top+iconBox.height/2
            }

            var contCenter = {
             x: contBox.left + contBox.width/2,
             y: contBox.top + contBox.height/2
            }

            var center = {
             x: (contCenter.x - iconCenter.x),
             y: (contCenter.y - iconCenter.y)
            }

            var distance = Math.min(Math.floor(this.utilDistance({x:0, y:0}, center)), contBox.width/2)
            var percent = Math.min((1 - distance/(contBox.width/2))*1.4, 0.99)

            var iconDraw = this.html.icons[i].getElementsByClassName('draw')[0]

            iconDraw.style.transform = `translateX(-50%) translateY(-50%) scale(${percent}, ${percent})`
            iconDraw.style.opacity = percent
         }
      },
      pos(e){
         var box = this.html.container.getBoundingClientRect()
         

         if(e?.clientX) {
            return {
               x: e.clientX - box.left,
               y: e.clientY - box.top
            }
         }
         else if(window.innerWidth > 768){
            let finalPointX = e.changedTouches[0]?.clientX;
            let finalPointY = e.changedTouches[0]?.clientY;
            

            return {
               x: finalPointX - box.left,
               y: finalPointY - box.top
            }
         }
      },
      utilDistance(pos1, pos2){

         return Math.sqrt(Math.pow((pos2.x - pos1.x),2) + Math.pow((pos2.y - pos1.y),2))
      },
      utilTransfer(obj){
         return JSON.parse(JSON.stringify(obj))
      },
      utilChoose(arr){
         return arr[Math.floor(Math.random()*arr.length)]
      },
   },
   unmounted () {
      clearInterval(this.interval)
   }
}
</script>

<style>
.info {
  position: fixed;
  top: 20px;
  left: 20px;
}

.draw__container {
  width: 100%;
  height: 100%;
  /*border-radius: 100%;*/
  /*padding: 0.25rem;*/
  box-sizing: border-box;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.draw-main__block {
  background-color: grey; /*#FF00AE;*/
  height: -webkit-fill-available;
  /*border-radius: 100%;*/
  /*padding: 0.5rem;*/
}

.video__container {
   /*
   background-color: wheat;
   height: -webkit-fill-available;
   border-radius: 100%;

   /*padding: 0.5rem;
   padding: 15.5rem;

   box-sizing: border-box;
   clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

       position: absolute;
    box-sizing: border-box;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;



   background-color: wheat;
   width: 9rem;
   height: 9rem;
   */

}
* {
   will-change: transform;
}
.container {
   
  width: 100vw;
  height: 100vh;
  overflow:hidden;
  user-select: none;
  margin: auto;
  position: relative;

  display:flex;
}
.video-preview__img {
-moz-user-select: none; -webkit-user-select: none; -ms-user-select: none; user-select: none
}
.container .help {
  position: absolute;
  top: -40px;
  left: 0px;
  width: 100%;
  text-align: center;
}
.container .plane {
  position: absolute;
  top: 0px;
  left: 0px;
  user-select: none;
}
.container .plane .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -13.33333px;
  gap: 56px;
  margin-top: -50px;
}
.container .plane .row:nth-child(even) {
  /*margin-left: 50px;*/
  margin-left: 83px;

}
.container .plane .row .icon {
  user-select: none;
  cursor: pointer;
  flex: 1;
  min-width: 110px;
  max-width: 110px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
}
.container .plane .row .icon:active {
  cursor: pointer;
}
.container .plane .row .icon .draw {
  user-select: none;
  background: transparent;
  width: 100%;
  height: 100%;
  /*position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  transform: translateX(-50%) translateY(-50%) scale(0.5, 0.5);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;*/
}
.container .plane .row .icon .draw:after{
   /*content: '';
   position:absolute;
   width:100%;
   height:100%;
   border-radius:100%;
   top:50%;
   left:50%;
   transform:translateX(-50%) translateY(-50%);
   transition:all 0.5s;
   */
}
.container .plane .row .icon.open{
   z-index:1000;
}
.container .plane .row .icon.open .draw:after{
 /*  transition:all 1s;
   width:500%;
   height:500%;
   background:rgba(0, 0, 0, 1);*/
}
.container .plane .row .icon .draw i {
  /*font-size: 36px;
  margin: auto;
  display: block;*/
}

.container .app{
   pointer-events:none;
   z-index:100000;
   opacity:0;
   color:#FFF;
   top:0px;
   left:0px;
   width:75%;
   margin:auto;
   font-size:28px;
   overflow:hidden;
   text-align:center;
   transition:all 0.25s;
}

.container .app .title{
   font-size:46px;
   font-weight:600;
   transition: all 0.25s;
   transition-delay:0.75s;
}
.container .app .item{
   margin-top:10px;
   font-weight:600;
   position:relative;
   width:auto;
   overflow:hidden;
   cursor:pointer;
   display:inline-block;
   left:100%;
   opacity:0;
   transition: 0s;
}
.container .app .item:nth-of-type(0){
   transition-delay: 0.25s;
}
.container.open .app .item{
   transition:all 0.25s;
   left:0px;
   opacity:1;
}
.container.open .app .item:nth-of-type(1){
   transition-delay: 0.5s;
}
.container.open .app .item:nth-of-type(2){
   transition-delay: 0.75s;
}
.container.open .app .item:nth-of-type(3){
   transition-delay: 1s;
}
.container.open .app .item:nth-of-type(4){
   transition-delay: 1.25s;
}
.container.open .app .item:nth-of-type(5){
   transition-delay: 1.5s;
}

.container .app .item:after{
   content:"";
   position:absolute;
   bottom:0px;
   left:-100%;
   height:4px;
   width:100%;
   opacity:0;
   background:#FFF;
   transition:all 0.25s;
}
.container .app .item:hover:after{
   left:0px;
   opacity:1;
}
.container .app .close{
   cccolor:#F22;
}
.container.open .app{
   transition: all 0.25s;
   transition-delay:0.75s;
   opacity:1 !important;
   z-index:10000;
   transform:translateX(0px) translateY(0px);
   pointer-events:initial;
}


</style>