export const lang = {
  state: {
    lang: [],
    currentLang: ''
  },
  getters: {
    lang (state) {
      return state.lang
    },
  },
  mutations: {
    SET_LANG (state, data) {
      state.lang = data
    },
  },
  actions: {
  },
  modules: {
    
  }
}