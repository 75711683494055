<template>
	<iframe width="300" height="200" :src="this.currentVideo?.url"></iframe>
	{{currentVideo}}
	zxc
</template>

<script>
export default {
	props: [
	],

	data () {
		return {

		}
	},
	computed: {
		currentVideo () {
			return this.$store.getters.currentVideo
		}
	}
}
</script>