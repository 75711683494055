<template>
  <div class="home">
    <grid/>
  </div>
</template>



<script>
import grid from '@/components/Home/grid.vue'
export default {
  name: 'HomeView',
  components: {
    grid
  },
  data () {
    return {
      
    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped>


body {
  display: -webkit-box;
  display: -ms-flexbox;

  overflow: hidden;
}


</style>