<template>
  <nav>
    <div class="burger__svg" @click="showNavModal = true">
      <div class="st-1"></div>
      <div class="st-2"></div>
      <div class="st-3"></div>
    </div>
    <div class="header-list__container">
      <ModalLeft @cancel="ModalCancel()" :show="showNavModal">
        <div class="header-modal__container">
          <div class="header-modal__list">
            <div v-for="item in navList" @click="goTo('/about')">
              Видео
            </div> 
          </div>
        </div>
      </ModalLeft>
    </div>

  </nav>
  <router-view/>
</template>

<script>
import ModalLeft from '@/components/Glob/ModalLeft.vue'

export default {
  components: {
    ModalLeft
  },
  data () {
    return {
      showNavModal: false,
      navList: [
        {
          title: 'Видео'
        },
        {
          title: 'Шортсы'
        },
        {
          title: 'Категории'
        }
      ]
    }
  },
  methods: {
    ModalCancel () {
      this.showNavModal = false 
    },
    goTo (url) {
      this.$router.push(url)
    }
  }
}
</script>

<style>
.mgp_tooltip {

}
body {
  padding: 0;
  margin: 0

}

html {
  font-size: 10px;
}
html, body {
  padding: 0px;
  margin: 0px;
  background: #222;
  font-family: 'Karla', sans-serif;
  color: #FFF;
  height: 100%;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 1.5rem
}

nav {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 100;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.burger__svg {
  width: 1.8rem;
  height: 1.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.st-1 {
  width: 100%;
  height: 0.3rem;
  background-color: white;
  border-radius: 0.5rem;
}
.st-2 {
  width: 100%;
  height: 0.3rem;
  background-color: white;
  border-radius: 0.5rem;
}
.st-3 {
  width: 100%;
  height: 0.3rem;
  background-color: white;
  border-radius: 0.5rem;
}

.close__svg {
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;  
}

</style>


<style scoped>
.header-modal__container {
  padding: 1rem 
}
.header-modal__list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

}
</style>