<template>
  <div class="about">
    <h1>Какиш</h1>
    <div class="videos__container">
      <div class="video__container" v-for="item in videos" @click="setVideo(item)">
        <!--<iframe width="300" height="200" :src="item.url"></iframe>-->
        <!--<div v-for="previews in item.preview">
          <img :src="previews"/>
        </div>-->
        <img :src="item.preview[0]"/>
        {{item.tags}} {{item.model}} {{item.title}}
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {
  },
  data () {
    return {
      currentVideo: null
    }
  },
  computed: {
    videos () {
      return this.$store.getters.videos
    }
  },
  mounted () {
    this.getAllvideos()
  },
  methods: {
    setVideo (item) {
      this.$store.commit('SET_VIDEO', item)
      this.$router.push('/video')
    },
    getAllvideos() {
      this.$store.dispatch('GET_VIDEOS')
    }
  }
}

</script>

<style>
.videos__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.video__container {
  width: 33%;
  display: flex;
  flex-direction: column;
}
</style>