<template>
	<div v-if="show" class="modal-container__bg" @click="cancel()"></div>
	<Transition name="glide">
		<div class="modal__container" v-if="show">
			<div class="header__nav">
				<img class="modal__logo">
				
				<div class="close-svg__container" @click="cancel()">
					<img class="close__svg" src="@/assets/icons/Glob/close.svg">
				</div>
			</div>
			<slot></slot>
		</div>
	</Transition>
</template>
<script>
export default {
	props: ['show'],
	data () {
		return {
			
		}
	},
	methods: {
		cancel () {
			this.$emit('cancel')
		}
	}
}
</script>

<style>

.modal-container__bg {
	position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 30%);
}
.modal__container {
	width: 70vw;
	left: 0;
	position: fixed;
	top: 0;
	background-color: white;
	height: 100%;

}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}



.glide-enter-active,
.glide-leave-active {
  transition: all 0.3s ease;
}

.glide-enter-from,
.glide-leave-to {
   transform: translateX(-70vw);
}


.header__nav {
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.close-svg__container {
	display: flex;
    align-items: center;
}

.modal__logo {
	background-color: black;
	width: 6rem;
    height: 2rem;
}
</style>